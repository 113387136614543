import { Box, BoxProps } from '@mui/material'
import PageHeaderBackButton from '~/src/components/marketplace/utils/PageHeaderBackButton'
import Title from '~/src/components/mui-wrappers/Title'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props extends BoxProps {
  title?: string,
  previousButton?: boolean,
}

const PageHeader = ({ title, previousButton = false, sx, ...boxProps }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        [muiTheme.breakpoints.down('md')]: {
          pt: `calc(var(--eo-safe-area-top) + ${muiTheme.spacing(5)})`,
          width: '100%',
          pb: 3,
          px: 2
        },
        ...sx
      }}
      {...boxProps}
    >
      <Box sx={{
        position: 'relative'
      }}>
        <PageHeaderBackButton previousButton={previousButton}/>
        <Title
          variant='h4'
          sx={{
            [muiTheme.breakpoints.down('md')]: {
              textAlign: 'center'
            }
          }}
        >
          {title}
        </Title>
      </Box>
    </Box>
  )
}

export default PageHeader
