import { SxProps } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import GoBackButton from '~/src/components/navigation/GoBackButton'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'

interface Props {
  previousButton: boolean,
}

const PageHeaderBackButton = ({ previousButton }: Props) => {
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const muiTheme = useCustomMuiTheme()
  const buttonSx: SxProps = {
    position: 'absolute',
    borderRadius: '50%',
    minWidth: 'unset',
    p: 1.5,
    border: 'none',
    color: 'inherit',
    boxShadow: muiTheme.elevation,
    top: '-5px'
  }

  if (!isMobile) {
    return null
  }

  if (previousButton) {
    return (
      <GoBackButton
        small
        sx={buttonSx}
      />
    )
  }

  return (
    <GoBackToTheShopButton
      small
      sx={buttonSx}
    />
  )
}

export default PageHeaderBackButton

